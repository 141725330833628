import React, { useEffect, useState } from "react";
import { Row, Col } from "react-grid-system";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import Carousel, { Modal, ModalGateway } from "react-images";
import { Link, useRouteMatch } from "react-router-dom";
import db from '../../Utils/firebase';
// Components
// import Headline from "../../components/common/Headline";
import {showLoader, hideLoader} from "./../../components/common/Loader";
import Fade from 'react-reveal/Fade';
import Slogan from "../../components/common/Slogan";


const Portfolio = () => {
    // const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    // const [selectedIndex, setSelectedIndex] = useState(0);
    const [WorkType, setWorkTypes] = useState([]);
    const [portfolio, setPortfolio] = useState([]);
    const [Filterportfolio, setFilterPortfolio] = useState([]);
    // const [images, setImages] = useState([]);

    useEffect(() => {
        showLoader();
        FetchWorkTypeData();
    }, [])

    const FetchWorkTypeData = async () => {

        await db.collection('root_collection').doc('some_document').collection('WorkTypes').get()
            .then(querySnapshot => {
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    arr.push(doc.data()?.workTypeName)
                });
                arr.unshift("All Work");
                setWorkTypes(arr);
            });

        await db.collection('root_collection').doc('some_document').collection('Portfolioes').get()
            .then(querySnapshot => {
                let arr = [];
                //   let arrimages = [];
                querySnapshot.docs.forEach(doc => {
                    console.log(doc.data());
                   // setlist(doc.data().title);
                   arr.push(doc.data())
                   //arrimages.push(doc.data().projectBanner)
               });
               // setImages(arrimages)
               arr.sort((a,b) => a.port_id - b.port_id);
                setPortfolio(arr);
                setFilterPortfolio(arr);
                hideLoader();
            });
    }

    // const toggleLightbox = (index) => {

    //     setSelectedIndex(index);
    //     setLightboxIsOpen(!lightboxIsOpen);
    // };

    // const filterPorfolioMethod = (category, categoryIndex) => {
    //     setSelectedIndex(categoryIndex)
    //     if (category == "All Work") {
    //         setFilterPortfolio(portfolio)
    //     }
    //     else {
    //         setFilterPortfolio([])
    //         let data = portfolio?.filter(obj => obj.workTypeName == category)

    //         setFilterPortfolio(data)
    //         console.log('data...', data)
    //     }
    // }

    const { path } = useRouteMatch();

    return (
        <section className="section section-portfolio section-portfolio-1">
            {Filterportfolio[0]?.projectName && 
            <div className="display-spacing">
                {/* <Container className="container"> */}
                    <header className="el-heading el-heading-center">
                        <div className="el-icon">
                            <h2 className="el-icon-title-about">
                                <span>Portfolio</span>
                            </h2>
                        </div>
                        <h3>Let's See My Work</h3>
                        <div className="divider divider-1-reverse divider-1-1"></div>
                        <div className="divider divider-1-reverse divider-1-2"></div>
                    </header>
                    {/* <Headline label="Portfolio" title="Let's See My Work" divider_1={true} position="center" /> */}
                    <div className="portfolio-item-section">
                <Row>
                    {Filterportfolio?.map((category, index) => {
                        return (
                            <Col md={6} sm={12} lg={4} key={index}>
                                <div className="portfolio-item">
                                    <div className="portfolio-card">
                                        <Fade top>
                                        <div className="image overlay-image" style={{ backgroundImage: `url(${category?.projectBanner?.src})`, borderRadius: '10px' }} />
                                        </Fade>
                                        <div className="portfolio-card-text">
                                            <div className="project-name">{category?.projectName}</div>
                                            <div className="project-workType">{category?.workTypeName}</div>
                                            <p className="portfolio-description" dangerouslySetInnerHTML={{ __html: category?.description }}></p>
                                            <div className="portfolio-button-section">

                                            
                                            <Link to={{ pathname: `${path}/projectDetail`, state: category }} className="button button-lg" style={{ backgroundColor: '#FF9C4F', borderRadius: 50, paddingHorizontal: 5, marginBottom: 5}}>
                                                            <span className="text" style={{color:'black', fontWeight: '600'}}>View more</span>
                                                        </Link>
                                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
                </div>
                <Slogan title='"Every app is special"'/>
                {/* </Container> */}
         
                {/* <ModalGateway>
                    {lightboxIsOpen ? (
                        <Modal onClose={toggleLightbox}>
                            <Carousel currentIndex={selectedIndex} views={images} />
                        </Modal>
                    ) : null}
                </ModalGateway> */}
            </div>
            }
        </section>
    );
}

export default Portfolio;
