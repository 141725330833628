import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import db from '../../Utils/firebase';
// Components
import ProgressBar from "../../components/common/ProgressBar";
import {showLoader, hideLoader} from "./../../components/common/Loader";
import Fade from 'react-reveal/Fade';

// const skills = [
//     {
//         title: "Web Design",
//         percent: 100,
//     },
//     {
//         title: "Mobile Apps",
//         percent: 90,
//     },
//     {
//         title: "Graphic Design",
//         percent: 75,
//     },
//     {
//         title: "Creativity",
//         percent: 90,
//     },
//     {
//         title: "Design Creation",
//         percent: 75,
//     },
//     {
//         title: "Marketing online",
//         percent: 90,
//     },
// ];
// const education = [
//     {
//         title: "Art University",
//         subtitle: "Art Director Course | New York",
//         description: "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//         date: "(2016-2018)",
//         icon: "ti-crown",
//     },
//     {
//         title: "Royal Holloway",
//         subtitle: "Bachelor Degree | London",
//         description: "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//         date: "(2016-2018)",
//         icon: "ti-cup",
//     },
//     {
//         title: "Stanford University",
//         subtitle: "Web Design Course | New York",
//         description: "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//         date: "(2016-2018)",
//         icon: "ti-ruler-alt-2",
//     },
// ];
// const experience = [
//     {
//         title: "UI/UX Designer",
//         subtitle: "Google INC | New York",
//         description: "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//         date: "(2016-2018)",
//         icon: "ti-pencil-alt",
//     },
//     {
//         title: "Front-End Developer",
//         subtitle: "Amazon INC | New York",
//         description: "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//         date: "(2016-2018)",
//         icon: "ti-heart",
//     },
//     {
//         title: "Back-End Developer",
//         subtitle: "Facebook INC | New York",
//         description: "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//         date: "(2016-2018)",
//         icon: "ti-paint-bucket",
//     },
// ];

const Resume = () => {
    const [education, setEducation] = useState();
    const [experience, setExperience] = useState([]);
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        showLoader();
        FetchResumedata()
    }, [])

    const FetchResumedata = async () => {

        await db.collection('root_collection').doc('some_document').collection('Educations').get()
            .then(querySnapshot => {
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    arr.push(doc.data())
               
                });
                arr.sort((a,b)=> a.displayorder - b.displayorder);
                setEducation(arr);
            });

            await db.collection('root_collection').doc('some_document').collection('Skills').get()
            .then(querySnapshot => {
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    arr.push(doc.data())
               
                });

                arr.sort((a,b)=> a.displayorder - b.displayorder);
                setSkills(arr)
            });

            await db.collection('root_collection').doc('some_document').collection('Experiences').get()
            .then(querySnapshot => {
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    arr.push(doc.data())
               
                });

                arr.sort((a,b)=> a.displayorder - b.displayorder);
                
                setExperience(arr)
                hideLoader();
            });
    }
    return (
        <section className="section section-resume section-resume-1">
            {experience[0]?.compunyAddress && 
            <div className="display-spacing">
                <header className="el-heading el-heading-center">
                    <div className="el-icon">
                        <h2 className="el-icon-title-about">
                            <span>Resume</span>
                        </h2>
                    </div>
                    <h3>Experience & Education</h3>
                    <div className="divider divider-1-reverse divider-1-1"></div>
                    <div className="divider divider-1-reverse divider-1-2"></div>
                </header>
                {/* <Container className="mb-spacing">
                    <Row>
                        <Col md={6}>
                            <div className="">
                                <h4 className="resume-title">Education</h4>
                                <ul>
                                    {education?.map((item, index) => (
                                        <li key={index}>
                                            <div className="resume-item">
                                                <div className="resume-head">
                                                    <div className="el-icon">
                                                        <span className={`el-icon-icon ${item?.iconName}`}></span>
                                                    </div>
                                                    <h5>{item?.universityName}</h5>
                                                    <h6>{item?.universitydegree_Address}</h6>
                                                </div>
                                                <div className="resume-body">
                                                    <p dangerouslySetInnerHTML={{__html: item?.description}}></p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="">
                                <h4 className="resume-title">Experience</h4>
                                <ul>
                                    {experience?.map((item, index) => (
                                        <li key={index}>
                                            <div className="resume-item">
                                                <div className="resume-head">
                                                    <div className="el-icon">
                                                        <span className={`el-icon-icon ${item?.iconName}`}></span>
                                                    </div>
                                                    <h5>{item?.positionName}</h5>
                                                    <h6>
                                                        {item?.compunyAddress} <small>{item.date}</small>
                                                    </h6>
                                                </div>
                                                <div className="resume-body">
                                                    <p dangerouslySetInnerHTML={{__html: item?.description}}></p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container> */}
                <Container className="mb-spacing">
                    <Row>
                        <Col md={6} className="resume-education-col">
                        <Fade left>
                            <div className="resume-row">
                            <h4 className="resume-title">Experience</h4>
                            {experience?.map((item, index) => {
                                                if (index === 0) {
                                                    return (
                                                        // <li >
                                                            <div className="resume-item" key={index}>
                                                                <div className="resume-head">
                                                                    {/* <div className="el-icon">
                                                            <span className={`el-icon-icon ${item?.iconName}`}></span>
                                                        </div> */}
                                                                    <h5>{item?.positionName}</h5>
                                                                    <h6>
                                                                        {item?.compunyAddress} <small>{item.date}</small>
                                                                    </h6>
                                                                </div>
                                                                <div className="resume-body">
                                                                    <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                                </div>
                                                            </div>
                                                        // </li>
                                                    )
                                                }
                                            }
                                    )}
                            
                            </div>

                            </Fade>
                        </Col>
                        <hr/>
                        <div className="first-round-circle"> <i className="ti-medall"></i></div>
                        <div className="second-round-circle"> <i className="ti-write"></i></div>
                        <div className="thired-round-circle"> <i className="ti-pencil"></i></div>
                        <Col md={6} className="resume-experience-col">
                        <Fade right>
                            <div className="resume-row resume-experience">
                         
                                <h4 className="resume-title">Experience</h4>
                                <ul>
                                    {experience?.map((item, index) => {
                                                if (index ===1) {
                                                    return (
                                                        <li key={index}>
                                                            <div className="resume-item">
                                                                <div className="resume-head">
                                                                    {/* <div className="el-icon">
                                                            <span className={`el-icon-icon ${item?.iconName}`}></span>
                                                        </div> */}
                                                                    <h5>{item?.positionName}</h5>
                                                                    <h6>
                                                                        {item?.compunyAddress} <small>{item.date}</small>
                                                                    </h6>
                                                                </div>
                                                                <div className="resume-body">
                                                                    <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            }
                                    )}
                                </ul>
                            </div>
                            </Fade>
                        </Col>
                        <hr/>
                        <div className="first-round-circle"> <i className="ti-medall"></i></div>
                        <div className="second-round-circle"> <i className="ti-write"></i></div>
                        <div className="thired-round-circle"> <i className="ti-pencil"></i></div>                 
                        <Col md={6} className="resume-education-col">
                        <Fade left>
                            <div className="resume-row">
                         
                                <h4 className="resume-title">Education</h4>
                               
                                <ul>
                                    {education?.map((item, index) => {
                                                if (index ===0) {
                                                    return (
                                                        <li key={index}>
                                                            <div className="resume-item">
                                                                <div className="resume-head">
                                                                    {/* <div className="el-icon">
                                                            <span className={`el-icon-icon ${item?.iconName}`}></span>
                                                        </div> */}
                                                                    <h5>{item?.universitydegree_Address}</h5>
                                                                    <h6>{item?.universityName}</h6>
                                                                </div>
                                                                <div className="resume-body">
                                                                    <p dangerouslySetInnerHTML={{__html: item?.description}}></p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                        }
                                    )}
                                </ul>
                            </div>
                            </Fade>
                        </Col>
                        <Col md={6} className="resume-experience-col">
                        <Fade right>
                            <div className="resume-row resume-experience">
                         
                                <h4 className="resume-title">Education</h4>
                                <ul>
                                    {education?.map((item, index) => {
                                                if (index ===1) {
                                                    return (
                                                        <li key={index}>
                                                            <div className="resume-item">
                                                                <div className="resume-head">
                                                                    {/* <div className="el-icon">
                                                            <span className={`el-icon-icon ${item?.iconName}`}></span>
                                                        </div> */}
                                                                    <h5>{item?.universitydegree_Address}</h5>
                                                                    <h6>{item?.universityName}</h6>
                                                                </div>
                                                                <div className="resume-body">
                                                                    <p dangerouslySetInnerHTML={{__html: item?.description}}></p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                        }
                                    )}
                                </ul>
                                
                            </div>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {skills?.map((item, index) => (
                            <Col key={index} sm={6} xl={4}>
                                <div className="skills-item">
                                    <ProgressBar label={item?.skillName} percent={parseInt(item?.skillpercentage)} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            }
        </section>
    );
}

export default Resume;
