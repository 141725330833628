import React, { useEffect, useState } from "react";
// import TextLoop from "react-text-loop";
// import Particles from "react-particles-js";
import { Row, Col, Hidden } from "react-grid-system";
import db from '../../Utils/firebase';
import { Link } from "react-router-dom";
// import About from "./About";
import {showLoader, hideLoader} from "./../../components/common/Loader";
import Fade from 'react-reveal/Fade';
import Slogan from "../../components/common/Slogan";
import Typed from 'react-typed';

const Intro = () => {

    const [info, setInfo] = useState();
    const [aboutinfo, setAboutInfo] = useState();
    const [followOptions, setFollowOptions] = useState([]);

    useEffect(() => {
        showLoader();
        Fetchdata()
    }, [])

    const Fetchdata = async () => {

        await db.collection('root_collection').doc('some_document').collection('Home').get()
            .then(querySnapshot => {
                // console.log(querySnapshot)
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    setInfo(doc.data())
                });
                hideLoader();
            });


        await db.collection('root_collection').doc('some_document').collection('About').get()
            .then(querySnapshot => {
                console.log(querySnapshot)
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    setAboutInfo(doc.data())
                });
             
            });

        await db.collection('root_collection').doc('some_document').collection('FollowMe').get()
            .then(querySnapshot => {
                console.log(querySnapshot)
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    arr.push(doc.data())
                });
                setFollowOptions(arr)
            });
    }
    return (
        <section className="section section-hero section-hero-1 overlay-image app-header-1">

            {
                info?.location &&
                <>
                <div className="display-center section-intro-1">
                    <>
                        <Row>
                            {/* <Hidden xs sm md> */}
                                <Col lg={6} md={12}>
                                    <div className="intro-image text-center">
                                        {/* <div className="image overlay-image" style={{ backgroundImage: `url('/assets/images/intro/intro1.png')` }}></div> */}

                                        <img src="/assets/images/intro/ruchita-intro.png" alt="intro1" className="ruchita-img"/>
                                        {/* <Hidden xs sm md> */}
                                        <Fade left>
                                        <img src="/assets/images/intro/happyClient.png" alt="happyClient" className="happyClientimg" />
                                        </Fade>
                                        {/* </Hidden> */}
                                        {/* <Hidden xs sm md> */}
                                        <Fade left>
                                        <img src="/assets/images/intro/yearsExperiencs.png" alt="yearsExperiencs" className="yearsExperiencs" />
                                        </Fade>
                                        {/* </Hidden> */}
                                        {/* <Hidden xs sm md> */}
                                        <Fade right>
                                        <img src="/assets/images/intro/bestServices.png" alt="bestServices" className="bestServices" />
                                        </Fade>
                                        {/* </Hidden> */}
                                    </div>
                                    <div className="header-follow">
                                        {/* <ul> */}

                                        <p>Follow us</p>
                                        <div className="line"></div>
                                        {
                                            followOptions?.map((item, index) => {
                                                return (
                                                    // <li key={index}>
                                                    <a href={item?.followLink} target="_blank" rel="noreferrer noopener" key={index}>
                                                        <i className={`${item?.iconName} `}></i>
                                                    </a>
                                                    // </li>
                                                )
                                            })
                                        }

                                        {/* </ul> */}
                                    </div>
                                </Col>
                            {/* </Hidden> */}
                            <Col lg={6} md={12} className="intro-text-container">
                                <div className="el-heading">
                                    <p className="el-icon">
                                        <div className="el-icon-title intro-username">{info?.userName}</div>
                                    </p>
                                    <h3>
                                         <Typed
                                        strings={["I'm Expert React-Native Developer","I'm Expert IOS Developer","I'm Expert React Developer"]}
                                        typeSpeed={150}
                                        backSpeed={100}
                                        loop
                                        />
                                        
                                        <br />
                                        
                                    </h3>
                                    <h4>{info?.location}</h4>

                                    <div className="intro-image-section">
                                        <Link to="/portfolio" className="button button-lg button-primary ">
                                            {/* <span className="wave"></span> */}
                                            <span className="text">{info?.portfolioBtnTitle}</span>
                                        </Link>

                                        {/* <Link to="/home-1/about" className="button button-lg">
                                        <span className="text">{info?.readMoreBtnTitle}</span>
                                    </Link> */}
                                    <Hidden xs sm>
                                        <img src="/assets/images/intro/arrow1.png" alt="arrow1" className="arrow-images" />
                                        </Hidden>
                                        <div className="intro-image-technology">
                                            <img src="/assets/images/intro/atom.png" alt="atom"/>
                                        </div>

                                        <div className="intro-image-technology">
                                            <img src="/assets/images/intro/apple.png" alt="apple"/>
                                        </div>
                                        <div className="intro-image-technology">
                                            <img src="/assets/images/intro/android.png" alt="android"/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Hidden lg xl>
                            <div className="home-slogan">
                                <Slogan title='"First, solve the problem. Then, write the code."'/>
                            </div>
                        </Hidden>
                    </>
                </div>
                <Hidden xs sm md>
                <div className="home-slogan">
                    <Slogan title='"First, solve the problem. Then, write the code."'/>
                </div>
                </Hidden>
            </>
            }
        </section>

    );
}

export default Intro;
