import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Row, Col } from "react-grid-system";
// Components
// import Headline from "../../components/common/Headline";
import {showLoader, hideLoader} from "./../../components/common/Loader";
import db from '../../Utils/firebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Fade from 'react-reveal/Fade';
import Slogan from "../../components/common/Slogan";

// const testimonial1 = [
//     {
//         name: "Rabie Maxwell",
//         type: "Clients",
//         post: "My Customer",
//         avatar: "/assets/images/people/1.jpg",
//         content: "You've saved our business! I can't say enough about you. We were treated like royalty. We can't understand how we've been living without you.",
//     },
//     {
//         name: "Sara Wander",
//         type: "Clients",
//         post: "My Customer",
//         avatar: "/assets/images/people/2.jpg",
//         content: "Dude, your stuff is the bomb! I love your system. It's all good. I was treated like royalty. Keep up the excellent work. I will definitely be ordering again!",
//     },
//     {
//         name: "Emy Jacman",
//         type: "Clients",
//         post: "My Customer",
//         avatar: "/assets/images/people/3.jpg",
//         content: "You've saved our business! I can't say enough about you. We were treated like royalty. We can't understand how we've been living without you.",
//     },
// ];

// const testimonial2 = [
//     {
//         name: "Kourosh Neyestani",
//         type: "Partners",
//         post: "Web Developer",
//         avatar: "/assets/images/people/4.jpg",
//         content: "Dude, your stuff is the bomb! I love your system. It's all good. I was treated like royalty. Keep up the excellent work. I will definitely be ordering again!",
//     },
//     {
//         name: "Rabie Maxwell",
//         type: "Partners",
//         post: "Web Designer",
//         avatar: "/assets/images/people/5.jpg",
//         content: "You've saved our business! I can't say enough about you. We were treated like royalty. We can't understand how we've been living without you.",
//     },
//     {
//         name: "James Maxwell",
//         type: "Partners",
//         post: "SEO Manager",
//         avatar: "/assets/images/people/6.jpg",
//         content: "Dude, your stuff is the bomb! Thank you so much for your help. You've saved our business! Just what I was looking for. It's exactly what I've been looking for.",
//     },
// ];

const detailsProjects = [
    {
        image: '/assets/images/testimonial/true1.png',
        title: '30+',
        description: 'Project Completed'
    },
    {
        image: '/assets/images/testimonial/person.png',
        title: '20+',
        description: 'Satisfied Client'
    },
    {
        image: '/assets/images/testimonial/true2.png',
        title: '7+',
        description: 'Years of experience '
    }
]

const Testimonial = () => {
    const [testimonial, setTestimonial] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        showLoader();
        FetchTestimonialdata();
    }, [])

    const FetchTestimonialdata = async () => {
            await db.collection('root_collection').doc('some_document').collection('Testimonials').get()
            .then(querySnapshot => {
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                     //console.log(doc.data())
                    // setlist(doc.data().title);
                    arr.push(doc.data())
                });
                
                arr.sort((a,b) => a.displayorder - b.displayorder);
                console.log(arr)
                setTestimonial(arr)
                hideLoader();
                setLoader(false);
            });
    }

    const sliderSettings = {
        dots: true,
        speed: 500,
        arrows: false,
        infinite: true,
        initialSlide: 0,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
            
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                },
            },
        ],
    };

    const defaulteImage = (name) => {
        let firstName = name.split(' ')[0];
        let lastName = name.split(' ')[1];

        const secondLatter = lastName && lastName !== undefined ? lastName[0] : ""

        let firstLetters = firstName[0] + secondLatter;

        return firstLetters;
    };

    return (
        <>
        <section className="section section-testimonial display-spacing">
            {!loader &&
            <>
            <div className="section-testimonial-1">
                {/* <Container className="container"> */}
                <header className="el-heading el-heading-center">
                        <div className="el-icon">
                            <h2 className="el-icon-title-about">
                                <span>Testimonial</span>
                            </h2>
                        </div>
                        <h3>Client Say Something</h3>
                        <div className="divider divider-1-reverse divider-1-1"></div>
                        <div className="divider divider-1-reverse divider-1-2"></div>
                    </header>
                    {/* <Headline label="Testimonial" title="Client Say Something " divider_1={true} position="center" /> */}
                    {/* <Row className="mb--15">
                        <Col lg={6} xl={6} className="mb-15"> */}
                            <Slider className="el-slider el-slider-plr--15 mb-100" {...sliderSettings}>
                                {testimonial?.map((item, index) => (
                                    <div key={index} className="testimonial-item testimonial-clients">
                                        <Row>
                                            <Col lg={4} xl={4} className="testimonial-clients-image-section">
                                            <Fade top>
                                                {item?.image?.src ? 
                                                    <LazyLoadImage
                                                        alt="Relevant textual alternative"
                                                        effect="blur"
                                                        src={item?.image?.src}
                                                        className="testimonial-image"
                                                    /> :
                                                    <div className="testimonial-image default-image-name">{defaulteImage(item?.customerName)}</div>
                                                }
                                                {/* <img className="testimonial-image" src={item?.image?.src} alt="Relevant textual alternative" /> */}
                                                </Fade>
                                            </Col>
                                            <Col lg={8} xl={8}>
                                                {/* <img src={item?.image?.src} alt="Relevant textual alternative" /> */}
                                                <div className="testimonial-name">
                                                    <div className="testimonial-name">{item?.customerName}</div>
                                                    <div> 
                                                        <img src="/assets/images/testimonial/star.png" alt="start1" />
                                                        <img src="/assets/images/testimonial/star.png" alt="start2" />
                                                        <img src="/assets/images/testimonial/star.png" alt="start3" />
                                                        <img src="/assets/images/testimonial/star.png" alt="start4" />
                                                        <img src="/assets/images/testimonial/star.png" alt="start5" />
                                                    </div>
                                                </div>
                                                <h5>{item?.customerdesignation}</h5>
                                                <div dangerouslySetInnerHTML={{__html: detailsProjects[0]?.title && item?.review}}></div>
                                                {/* <div className="author">
                                                   <h4>{item?.customerName}</h4>
                                                  <h5>{item?.customerdesignation}</h5>
                                                </div> */}
                                            </Col>
                                        </Row>
                                        {/* <div className="type">
                                            <span>{item?.customerType}</span>
                                        </div>
                                        <p dangerouslySetInnerHTML={{__html: item?.review}}></p>
                                        <div className="author">
                                            <img src={item?.image?.src} alt="Relevant textual alternative" />
                                            <h4>{item?.customerName}</h4>
                                            <h5>{item?.customerdesignation}</h5>
                                        </div> */}
                                    </div>
                                ))}
                            </Slider>
                       
                            
                        {/* </Col> */}
                        {/* <Col lg={6} xl={6} className="mb-15">
                            <Slider className="el-slider" {...sliderSettings}>
                                {testimonial2.map((item, index) => (
                                    <div key={index} className="testimonial-item testimonial-clients">
                                        <div className="type">
                                            <span>{item.type}</span>
                                        </div>
                                        <p>{item.content}</p>
                                        <div className="author">
                                            <img src={item.avatar} alt="Relevant textual alternative" />
                                            <h4>{item.name}</h4>
                                            <h5>{item.post}</h5>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </Col> */}
                    {/* </Row> */}

              
                    {/* <div className="div-center text-center lets-work">
                        <button type="button" className="button button-md button-primary">
                            let's work together
                        </button>
                    </div> */}
                {/* </Container> */}
            </div>

            <div className="project-details">
                <Row>
                    {
                        detailsProjects.map((detail, index) => {
                            return (
                                <Col lg={4} xl={4} key={index}>
                                    <div className="project-details-rows">
                                        <img src={detail.image} alt="testimonialImage" />
                                        <div>
                                            <div className="detail-title">
                                                {detail.title}
                                            </div>
                                            <div className="detail-description">
                                                {detail.description}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            </>
             }
              <div className="testimonial-slogan">
             {!loader && <Slogan title='"I’m not a great programmer; I’m just a good programmer with great habits."'/>}
             </div>
        </section>
             
             </>
    );
}

export default Testimonial;
