import firebase from 'firebase';

export const firebaseConfig = {
  apiKey: "AIzaSyCd6EcVe9KeWni9n900jzTt9D5MfcIj7Ms",
  authDomain: "portfolioapp-fa0ca.firebaseapp.com",
  projectId: "portfolioapp-fa0ca",
  storageBucket: "portfolioapp-fa0ca.appspot.com",
  messagingSenderId: "421001484679",
  appId: "1:421001484679:web:f6708d0dfb7d9184f15b22",
  measurementId: "G-XF31L7KQQT"
};
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

export default db;
