import React from "react";
// import { FaCheck } from "react-icons/fa";
import { Row, Col } from "react-grid-system";
// Components
// import db from '../../Utils/firebase';
// import GoBack from "../../components/common/GoBack";
import { useLocation } from 'react-router-dom';

const ProjectDetail = ({ history, match }) => {
    
    const { state } = useLocation();

    return (
        <section className="section section-single-post section-single-post-1">
            <div className="display-spacing">
                {/* <Container> */}
                    <div className=" div-center">
                        <div className="post">
                            <div className="post-head">
                                <div class="post-info">
                                <Row>
                                    <Col lg={5} xl={5} className="post-info-text-sections">
                                        {/* <GoBack /> */}
                                        <h1>{state?.projectName}</h1>
                                        <p dangerouslySetInnerHTML={{ __html: state?.projectSubTitle }}>
                                            {/* by <span className="author">Miranda Smith</span> in <span className="category">Business</span> on <span class="date">May 1, 2020</span>. */}
                                        </p>

                                        <div className="available-app">
                                            Available on app
                                            <a href={state?.ioslink} target="_blank" rel="noreferrer noopener">
                                        <img src="/assets/images/storeImages/app-store.svg" alt="appStore"/>
                                        </a>
                                        </div>

                                    </Col>
                                    <Col lg={7} xl={7}>
                                        <div className="post-image">
                                            <img src={state?.projectBanner?.src} alt="Single Post" />
                                        </div>
                                    </Col>
                                </Row>
                                    
                                </div>
                            </div>
                            <div className="post-body">
                                
                                {/* <div class="post-tags" style={{marginBottom: 10}}>
                                    
                                    <ul>
                                        {
                                            state?.ioslink &&
                                            <li className="el-icon" >
                                              <a href={state?.ioslink} target="_blank" rel="noreferrer noopener">  <span className="el-icon-icon ti-apple"></span> </a>
                                            </li>
                                        }
                                        {
                                            state?.androidlink &&
                                            <li className="el-icon">
                                                <a href={state?.androidlink} target="_blank" rel="noreferrer noopener">  <span className="el-icon-icon ti-android"></span></a>
                                            </li>
                                        }
                                        {
                                            state?.weblink &&
                                            <li className="el-icon">
                                                <a href={state?.weblink} target="_blank" rel="noreferrer noopener">  <span className="el-icon-icon ti-dribbble"></span></a>
                                            </li>
                                        }
                                  
                                    </ul>


                                </div> */}

                                <div className="post-content">

                                    <p dangerouslySetInnerHTML={{ __html: state?.description }}></p>
                                    {/* <p>
                                        <b>Key Features:</b>
                                    </p>
                                    <ul class="el-list-check">
                                        <li>
                                            <FaCheck className="icon" />
                                            Clean Code
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Modern Design
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            Great Support
                                        </li>
                                        <li>
                                            <FaCheck className="icon" />
                                            High Resolution
                                        </li>
                                    </ul>
                                    <p>Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>
                                    <p>Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem.</p>
                                    <p>Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p> */}
                                </div>
                                {/* <div class="post-tags">
                                    <span>Tags:</span>
                                    <ul>
                                        <li>
                                            <a href="/#">Business</a>
                                        </li>
                                        <li>
                                            <a href="/#">Marketing</a>
                                        </li>
                                        <li>
                                            <a href="/#">Corporate</a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                {/* </Container> */}
            </div>
        </section>
    );
}

export default ProjectDetail;
