import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Container, Hidden } from "react-grid-system";
import db from '../../Utils/firebase';
import {showLoader, hideLoader} from "./../../components/common/Loader";


const Service = () => {
  
    const [service, setService] = useState([]);

    useEffect(() => {
        showLoader();
        FetchServicesdata()
    }, [])

    const FetchServicesdata = async () => {

       
            await db.collection('root_collection').doc('some_document').collection('Skills').get()
            .then(querySnapshot => {
                console.log(querySnapshot)
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    if (doc.data().description) {
                        arr.push(doc.data())
                    }
               
                });
                setService(arr);
                hideLoader();
            });
    }

    let sliderSettings = {
        dots: true,
        speed: 500,
        arrows: false,
        infinite: true,
        initialSlide: 0,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            // {
            //     breakpoint: 1500,
            //     settings: {
            //         slidesToShow: 4,
            //         slidesToScroll: 4,
            //         centerMode: true,
            //         centerPadding: 50,
            //     },
            // },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerMode: true,
                    centerPadding: 50,
                },
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    centerPadding: 50,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: 100,
                },
            },
            {
                breakpoint: 570,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="section section-service section-service-5">
            <div className="display-spacing mb-0">
                <Container>
                    <header className="el-heading el-heading-center">
                        <div className="el-icon">
                            <h2 className="el-icon-title-about">
                                <span>SERVICE</span>
                            </h2>
                        </div>
                        <h3>High-quality development guaranteed</h3>
                        <div className="divider divider-1-reverse divider-1-1"></div>
                        <div className="divider divider-1-reverse divider-1-2"></div>
                    </header>
                   
                    <Hidden xs sm md>
                    <Slider className="el-slider el-slider-plr--15 mb-30" {...sliderSettings}>
                        {service?.map((item, index) => (
                            // <div className={`banner ${active ? "active" : ""}`}>{children}</div>
                            item.description && 
                            <div className={`service-wrap ${index % 2 ? "itemDevide" : ""}`} key={index}>
                            <hr className={`hr-${index}`}/>
                            <hr className={`h-horizontal-${index}`} />
                                <div className="service-item">
                                    <div className="service-head">
                                        <div className="image-container">
                                        <img src={`/assets/images/service/${item?.iconName}`} alt="iosimage" className={`icon-${index}`}/>
                                        </div>
                                    </div>
                                    <div className="service-body">
                                        <h3>{item?.skillName}</h3>
                                        <p dangerouslySetInnerHTML={{__html: item?.description}}></p>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </Slider>
                    </Hidden>

                    <Hidden lg xl>
                    {service?.map((item, index) => (
                            // <div className={`banner ${active ? "active" : ""}`}>{children}</div>
                            item.description && 
                            <div className={`service-wrap ${index % 2 ? "itemDevide" : ""}`} key={index}>
                            <hr className={`hr-${index}`}/>
                            <hr className={`h-horizontal-${index}`} />
                                <div className="service-item">
                                    <div className="service-head">
                                        <div className="image-container">
                                        <img src={`/assets/images/service/${item?.iconName}`} alt="iosimage" className={`icon-${index}`}/>
                                        </div>
                                    </div>
                                    <div className="service-body">
                                        <h3>{item?.skillName}</h3>
                                        <p dangerouslySetInnerHTML={{__html: item?.description}}></p>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </Hidden>
                </Container>
            </div>
        </section>
    );
}

export default Service;
