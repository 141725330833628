import React from "react";

const Slogan = ({ title }) => {
    return (
        <div className="slogan-title">
            {title}
        </div>
    )
};

export default Slogan;
