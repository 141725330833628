import React, { useEffect, useState } from "react";
import { Hidden, Row, Col } from "react-grid-system";
import db from '../../Utils/firebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import "react-lazy-load-image-component/src/effects/blur.css";
import {showLoader, hideLoader} from "./../../components/common/Loader";
import Fade from 'react-reveal/Fade';

const About = () => {

    const [aboutinfo, setAboutInfo] = useState();
    const [skillList, setSkills] = useState([]);

    useEffect(() => {
        showLoader();
        FetchAboutdata()
        
    }, [])

    const FetchAboutdata = async () => {

        await db.collection('root_collection').doc('some_document').collection('About').get()
            .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    setAboutInfo(doc.data())
                });
                hideLoader();
            });

        await db.collection('root_collection').doc('some_document').collection('Skills').get()
            .then(querySnapshot => {
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    arr.push(doc.data())

                });
                setSkills(arr)
            });
    }

    return (
        <section className="section section-about section-about-1">
            <div className="display-spacing">
                {/* <Container> */}
                    <Row>
                        {/* <Hidden xs sm md> */}
                            <Col lg={5}>
                            {/* <LoadingSpinner /> */}
                            <Fade left>
                                <div className="about-image">
                                    {/* <div className="image overlay-image" style={{ backgroundImage: `url('${aboutinfo?.sideImage?.src}')` }}></div> */}
                                    <div className="image overlay-image">
                                    {/* <img src={aboutinfo?.sideImage?.src}/> */}
                                    {window !== 'undefine' && window.innerWidth > 1024 ? 
                                     <LazyLoadImage
                                     alt="about"
                                     effect="blur"
                                     src="/assets/images/about/ruchita-about.png"
                                 />
                                    : 
                                    <LazyLoadImage
                                    alt="about"
                                    effect="blur"
                                    src="/assets/images/about/ruchita-about-mobile.png"
                                />}
                                    {/* <LazyLoadImage
                                        alt="about"
                                        effect="blur"
                                        src="/assets/images/about/ruchita-about.png"
                                    /> */}
                                    </div>
                                </div>
                                </Fade>
                            </Col>
                        {/* </Hidden> */}
                        <Col lg={7}>
                            {aboutinfo?.aboutUs && 
                        <Fade right>
                            <div className="about-content">
                                <header className="el-heading">
                                    <h2 className="el-icon">
                                        <span className="el-icon-title-about">About us</span>
                                    </h2>
                                    <h3>
                                        <span>{aboutinfo?.userName}</span>
                                    </h3>
                                    <div>
                                        <div className="divider divider-1-reverse divider-1-1"></div>
                                        <div className="divider divider-1-reverse divider-1-2"></div>
                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: aboutinfo?.aboutUs }} ></p>
                                </header>
                                {/* <Row className="pt-30">
                                    {
                                        skillList?.map((item, index) => {
                                            return (
                                                <Col xs={12} sm={12} md={6}>
                                                    <div className="el-card-icon">
                                                        <div className="el-icon">
                                                            <span className={`el-icon-icon ${item?.iconName}`}></span>
                                                        </div>
                                                        <div className="content">
                                                            <h5>{item?.skillName}</h5>
                                                            <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                        </div>
                                                    </div>
                                                </Col>)
                                        </Col>) 
                                                </Col>)
                                        })

                                    }

                                </Row> */}
                            </div>
                            </Fade> 
                            }
                        </Col>
                    </Row>
                {/* </Container> */}
            </div>
        </section>
    );
}

export default About;
