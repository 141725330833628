import React, { useEffect } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
// Sections
import Blog from "./Blog";
import About from "./About";
import Intro from "./Intro";
import Header from "./Header";
import Resume from "./Resume";
import Service from "./Service";
import Contact from "./Contact";
import Portfolio from "./Portfolio";
import Testimonial from "./Testimonial";
import BlogSinglePost from "./BlogSinglePost";
// Components
import Helmet from "../../components/common/Helmet";
import Switch from "../../components/common/Switch";
import RedirectAs404 from "../../components/common/RedirectAs404";
import ProjectDetail from "./ProjectDetail";
// import Slider from "react-slick";

const routes = [
    {
        path: "/",
        component: <Intro />,
    },
    {
        path: "/about",
        component: <About />,
    },
    {
        path: "/resume",
        component: <Resume />,
    },
    {
        path: "/service",
        component: <Service />,
    },
    {
        path: "/portfolio",
        component: <Portfolio />,
    },
    {
        path: "/portfolio/projectDetail",
        component: <ProjectDetail />,
    },
    {
        path: "/blog",
        component: <Blog />,
    },
    {
        path: "/blog/single-post",
        component: <BlogSinglePost />,
    },
    {
        path: "/testimonial",
        component: <Testimonial />,
    },
    {
        path: "/contact",
        component: <Contact />,
    },
];

function Home() {
    let { path } = useRouteMatch();

    useEffect(() => {
        document.documentElement.className = "home-1 skin-1";
        return () => {
            document.documentElement.className = "";
        };
    });

    // const sliderSettings = {
    //     dots: true,
    //     speed: 500,
    //     arrows: false,
    //     infinite: true,
    //     initialSlide: 0,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     responsive: [

    //         {

    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             },
    //         },

    //     ],
    // };

    return (
        <div>
            <Helmet title="Home 1" />
            <Header />
            <Switch>
                {/* <Route path={path} exact>
                    <Redirect
                        to={{
                            pathname: `/`.replace(/([^:])(\/\/+)/g, "$1/"),
                        }}
                    />
                </Route> */}
                {/* <Slider className="el-slider" {...sliderSettings}> */}
                    {routes.map((item, index) => (
                        <Route key={index} path={`${item.path}`} exact>
                            {item.component}
                        </Route>
                    ))}
                {/* </Slider> */}
                <Route component={RedirectAs404} />
            </Switch>

        </div>
    );
}

export default Home;
