import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
// Stylesheet
import "./index.scss";
import "./mycss.css";
// Scenes
// import Demo from "./scenes/Demo";
import Home from "./scenes/Home";
// import Home2 from "./scenes/Home2";
// import Home3 from "./scenes/Home3";
// import Home4 from "./scenes/Home4";
// import Home5 from "./scenes/Home5";
// import Home6 from "./scenes/Home6";
// Components
import Error404 from "./components/common/Error404";
import RedirectAs404 from "./components/common/RedirectAs404";
// import ScrollToTop from "./Utils/ScrollToTop";
const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');

const hideLoader = () => loader.classList.add('loader--hide');

const Root = ({ hideLoader }) => {
    
    useEffect(hideLoader, []);
    
    // useEffect(() => {
    //     window.history.scrollRestoration = 'manual';}, []);
    return (
        <div>
            <Switch>
                {/* <Route exact path="/" component={Home} /> */}
                <Route path="/" component={Home} />
                {/* <Route path="/home-2" component={Home2} />
                <Route path="/home-3" component={Home3} />
                <Route path="/home-4" component={Home4} />
                <Route path="/home-5" component={Home5} />
                <Route path="/home-6" component={Home6} /> */}
                <Route component={RedirectAs404} />
            </Switch>
        </div>
    );
};
 
setTimeout(() =>
    ReactDOM.render(
        <HashRouter>
            <div>
                {/* <ScrollToTop/> */}
                <Route render={({ location }) => (location.state && location.state.is404 ? <Error404 /> : <Root 
                  hideLoader={hideLoader}
                  showLoader={showLoader} 
                  />)} />
            </div>
        </HashRouter>,
        document.getElementById("root")
    )
    , 2000);
serviceWorker.unregister();
