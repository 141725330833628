import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { 
    // FaTwitter, FaGithub, FaInstagram, FaLinkedin, FaFacebook, 
    FaBars, FaWhatsapp, FaEnvelope } from "react-icons/fa";
// Components
// import DemoOptions from "../../components/common/DemoOptions";
import db from '../../Utils/firebase';

const links = [
    {
        title: "Home",
        path: "/",
    },
    {
        title: "About",
        path: "/about",
    },
    {
        title: "Resume",
        path: "/resume",
    },
    {
        title: "Service",
        path: "/service",
    },
    {
        title: "Portfolio",
        path: "/portfolio",
    },
    // {
    //     title: "Blog",
    //     path: "/blog",
    // },
    {
        title: "Testimonial",
        path: "/testimonial",
    },
    {
        title: "Contact",
        path: "/contact",
    },
];

function Header() {
    // let { url } = useRouteMatch();

    const [displaySidenav, setDisplaySidenav] = useState(false);
    const [followOptions, setFollowOptions] = useState([])

    const handleClick = (e) => {
        e.preventDefault();
        setDisplaySidenav(!displaySidenav);
    };

    useEffect(() => {

        FetchFollowMeData()
    }, [])

    const FetchFollowMeData = async () => {

        await db.collection('root_collection').doc('some_document').collection('FollowMe').get()
            .then(querySnapshot => {
                console.log(querySnapshot)
                let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    arr.push(doc.data())


                });
                setFollowOptions(arr)
            });
    }

    return (
        <>

            <header className="app-header app-header-1">
                {/* <div className="header-logo">
                    <Link to={`url`.replace(/([^:])(\/\/+)/g, "$1/")}>Miro.</Link>
                </div> */}
                {/* <button className="button button-menu" onClick={(e) => handleClick(e)}>
                    <span></span>
                </button> */}
                {/* <ul className="header-dots">
                    {links.map((item, index) => (
                        <li key={index}>
                            <NavLink to={`${url}${item.path}`} activeClassName="active">
                                <span className="circle"></span>
                            </NavLink>
                        </li>
                    ))}
                </ul> */}
                <div className="header-number-email">

                <a href="tel:9586323070">
                    <div className="number">
                    <FaWhatsapp size={25} color="#111111" className="icon-hedaer"/>
                        +91 9586323070
                    </div>
                    </a>
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=patoliyaruchitawork@gmail.com" target="_blank">
                    <div className="email">
                    <FaEnvelope size={25} color="#111111" className="icon-hedaer"/>
                        patoliyaruchitawork@gmail.com
                    </div>
                    </a>
                </div>
                <ul className="header-menu">
                <a href="/" rel="noreferrer noopener">
                <img src="/assets/images/header/rp-logo.png" alt="logo"/>
                    <div className="name">Ruchita Patoliya</div>
                    </a>
                    <div className="menu-text">
                    {links.map((item, index) => (
                        <li key={index}>
                            <NavLink to={`${item.path}`} activeClassName="active" exact>
                                <span className="anchor">{item.title}</span>
                            </NavLink>
                        </li>
                    ))}

                    {/* <div> */}
                    <button className="button button-menu" onClick={(e) => handleClick(e)}>
                        <FaBars size={25} color="#FF9C4F"/>
                    </button>
                    {/* </div> */}
                    </div>
                    
                </ul>
                {/* <div className="header-follow">
                    <ul>

                        {
                            followOptions?.map((item, index) => {
                                return (

                                    <li>
                                        <a href={item?.followLink} target="_blank" rel="noreferrer noopener">
                                            <i className={item?.iconName}></i>
                                        </a>
                                    </li>
                                )
                            })
                        }

                    </ul>
                    <p>Follow Me</p>
                </div> */}
            </header>
            <div className={`app-sidenav ${displaySidenav && "active"}`}>
                <div className="sidenav-menu">
                    {/* <div className="logo">
                        <Link to={`url`.replace(/([^:])(\/\/+)/g, "$1/")}>Miro</Link>
                    </div> */}
                    <div>
                    <div className="header-number-email-mobile">
                    <a href="tel:9586323070">
                    <div className="number">
                    <FaWhatsapp size={25} color="#111111" className="icon-hedaer"/>
                        +91 9586323070
                    </div>
                    </a>
                    <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=patoliyaruchitawork@gmail.com" target="_blank">
                    <div className="email">
                    <FaEnvelope size={25} color="#111111" className="icon-hedaer"/>
                        patoliyaruchitawork@gmail.com
                    </div>
                    </a>
                </div>
                    <button className="button" onClick={(e) => handleClick(e)}></button>
                    </div>
                    
                    <ul className="sidenav-nav">
                        {links.map((item, index) => (
                            <li key={index} onClick={(e) => handleClick(e)}>
                                <NavLink to={`${item.path}`.replace(/([^:])(\/\/+)/g, "$1/")} activeClassName="active" exact> 
                                    <span className="anchor">{item.title}</span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <div className="sidenav-links">
                        <ul>

                            {
                                followOptions?.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={item?.followLink} target="_blank" rel="noreferrer noopener">
                                                <i className={item?.iconName}></i>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <p>Follow Me</p>
                    </div>
                </div>
                <span className="sidenav-close" />
            </div>

            {/* <DemoOptions /> */}
        </>
    );
}

export default Header;
