import React, { useEffect, useState } from "react";
import { Hidden, Row, Col } from "react-grid-system";
import db from '../../Utils/firebase';
import emailjs from '@emailjs/browser';
import Fade from 'react-reveal/Fade';
import Slogan from "../../components/common/Slogan";
// var elasticemail = require('elasticemail');
// const nodemailer = require('nodemailer');
// var client = elasticemail.createClient({
//     username: 'reactnativedeveloper013@gmail.com',
//     apiKey: '7D9B0821385E8E19D4BBAF1D3A3352A502C42409B199FE227DC4B76A3B1E3E890FD213C81E553ED9D121370C17C90EA3'
// });
// const transporter = nodemailer.createTransport({
//     service: 'gmail',
//     auth: {
//         user: 'ruchitatesting01@gmail.com',
//         pass: 
//     }
// });
const Contact = () => {
    const [contectinfo, setContactInfo] = useState([]);
    const [username, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        FetchContactContentData()
    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault();

        const templateParams = {
            user_email: e.target.email.value,
            from_email: e.target.email.value,
            username: e.target.username.value,
            reply_to: e.target.email.value,
            message: e.target.message.value
        };
        

        emailjs.send('service_rcmfude', 'template_oc7wrzl', templateParams , 'zKgR3CqpY8tNFqupl')
            .then((response) => {
                alert('Message Successfully Sent!')
                console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                alert('Somthing went wrong.')
                console.log('FAILED...', err);
            });

        
        if (e.target.email.value !== "" && e.target.message.value !== "" && e.target.username.value !== "") {
            await db.collection('root_collection').doc('some_document').collection('ContactList').add({
                name: e.target.username.value,
                email: e.target.email.value,
                message: e.target.message.value,
                createdAt: new Date()
            })
                .then(function () {
                    console.log("Document successfully written!");
                })
                .catch(function (error) {
                    console.error("Error writing document: ", error);
                });
        }

    }

    const FetchContactContentData = async () => {

        await db.collection('root_collection').doc('some_document').collection('ContactContent').get()
            .then(querySnapshot => {
                // let arr = [];
                querySnapshot.docs.forEach(doc => {
                    // setlist(doc.data().title);
                    setContactInfo(doc.data());
                });

            });
    }
    return (
        <section className="section section-contact section-contact-1">
             {contectinfo?.title &&
            <div className="display-spacing">
                {/* <Container> */}
                        <Row>
                            
                            <Col lg={5}>
                                <Hidden xs sm md>
                                    <Fade left>
                                    
                                        <div className="contact-image">
                                            {/* <div className="image overlay-image" style={{ backgroundImage: `url('/assets/images/contact/contact.png')` }}></div> */}
                                            <img src="/assets/images/contact/contact.png" className="contactimag" alt="contact"/>
                                        </div>
                                    </Fade>
                                </Hidden>
                                <Fade bottom>
                                <Row className="contact-detailscls">
                                    <Col md={6} sm={12} lg={6}>
                                        <h4>Phone</h4>
                                        <a target="_blank" href="tel:+916354273304" >
                                            <div className="detail">
                                                <i class="fa fa-phone"></i>
                                                <p>+91 6354273304</p>
                                            </div>
                                        </a>
                                    </Col>
                                    <Col md={6} sm={12} lg={6}>
                                        <h4>Whatsapp</h4>
                                        <a target="_blank" href="https://wa.me/916354273304?text=hii" >
                                            <div className="detail">
                                                <i class="fa fa-whatsapp"></i>
                                                <p>+91 6354273304</p>
                                                
                                            </div>
                                        </a>
                                    </Col>
                                    <Col md={6} sm={12} lg={6}>
                                        <h4>Skype</h4>
                                        <a target="_blank" href="skype:live:.cid.8f846452ab72ea33?chat" >
                                            <div className="detail">
                                                <i class="fa fa-skype"></i>
                                                <p>Ruchita Patoliya</p>
                                            </div>
                                        </a>    
                                    </Col>
                                    <Col md={6} sm={12} lg={6}>
                                        <h4>Email</h4>
                                        <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=ruchitapatoliya17@gmail.com" target="_blank">
                                            <div className="detail">
                                                <i class="fa fa fa-envelope-o"></i>
                                                <p>ruchitapatoliya17@gmail.com</p>
                                            </div>
                                        </a>    
                                    </Col>
                                </Row>
                                </Fade>
                            </Col>
                            
                        
                            
                        <Col lg={7}>
                        <Fade right>
                            <div className="contact-content">
                                <header className="el-heading">
                                    <h2 className="el-icon">
                                        <span className="el-icon-title-about">Contact me</span>
                                    </h2>
                                    <h3>
                                        <span>{contectinfo?.title}</span>
                                    </h3>
                                    <div>
                                        <div className="divider divider-1-reverse divider-1-1"></div>
                                        <div className="divider divider-1-reverse divider-1-2"></div>
                                    </div>
                                    {/* <div className="divider divider-1"></div> */}
                                    {/* <p dangerouslySetInnerHTML={{ __html: contectinfo?.description }}></p> */}
                                </header>
                                <form className="form form-1 pt-15" onSubmit={e => { handleSubmit(e) }}>
                                    <Row className="row">
                                        <Col xs={12} sm={12} md={6}>
                                            <div className="form-item">
                                                <input type="text" id="form-item-name"
                                                    name="username"
                                                    value={username}
                                                    onChange={e => setUserName(e.target.value)} />
                                                <label htmlFor="form-item-name">Your Name</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={6}>
                                            <div className="form-item">
                                                <input type="email" id="form-item-email" name="email" value={email}
                                                    onChange={e => setEmail(e.target.value)} />
                                                <label htmlFor="form-item-email">Your Email</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12}>
                                            <div className="form-item">
                                                <textarea id="form-item-message"
                                                    name="message"
                                                    value={message}
                                                    onChange={e => setMessage(e.target.value)}
                                                ></textarea>
                                                <label htmlFor="form-item-message">Your Message</label>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12}>
                                             <button className={`${!username || !email || !message ? 'submit-button' : 'button-primary'} button button-md`} type="submit">
                                                Send Message
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                            </Fade>

                        </Col>
                    </Row>
                    <div className="contact-slogan">
                        <Slogan title={`"9 women cannot make a baby in one month. So don't delay to contact me…😊"`}/>
                    </div>
                {/* </Container> */}
            </div>
             }
        </section>
        
    );
}

export default Contact;
